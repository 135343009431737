














import { Component } from 'vue-property-decorator'
import AbstractField from '@/classes/Form/Fields/AbstractField'
import FormField from '@/components/Form/FormField.vue'

@Component({
  components: { FormField },
})
export default class ObjectField extends AbstractField {}
